import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";
import BlogPostPreviewGrid from "../components/blog-post-preview-grid";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import "../styles/layout.scss";

export const query = graphql`
  query BlogPageQuery {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          categories {
            title
          }
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    categories: allSanityCategory {
      edges {
        node {
          title
          id
        }
      }
    }
  }
`;

const BlogPage = props => {
  const { data, errors } = props;

  const [activeFilter, setActiveFilter] = useState(null);
  const [filteredPosts, setFilteredPosts] = useState([]);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts);
  const categories = data && data.categories && mapEdgesToNodes(data.categories);

  useEffect(() => {
    let filteredPosts = postNodes.filter(post => {
      const postCategories = post.categories.map(category => {
        return category.title;
      });
      if (postCategories.includes(activeFilter)) {
        return post;
      } else if (activeFilter === null) {
        return post;
      } else {
        return null;
      }
    });
    setFilteredPosts(filteredPosts);
  }, [activeFilter]);

  return (
    <Layout>
      <SEO title="Blog" />
      <Container>
        <h1 className="preview-grid__headline">Blogg</h1>
        <div className="filter">
          <div className="filter__buttons">
            {categories.map(category => (
              <button
                onClick={() => {
                  if (activeFilter === category.title) {
                    setActiveFilter(null);
                  } else {
                    setActiveFilter(category.title);
                  }
                }}
                className={`filter__button ${
                  activeFilter && activeFilter === category.title ? "filter__button--active" : ""
                }`}
              >
                {category.title}
              </button>
            ))}
          </div>
          {activeFilter &&
          activeFilter === "Kunnskapslab" &&
          filteredPosts &&
          filteredPosts.length > 0 ? (
            <div style={{ paddingTop: "70px" }}>
              <p>
                I kunnskapslab’en vår finner du artikler basert på en del av kommunikasjonen med
                utøvere i PG Treningslab gjennom fire år. Alt dette er nå tilgjengelig for alle! Her
                er mye nyttig, lærerikt og interessant for alle som liker løping, sykling og trening
                generelt!
              </p>
            </div>
          ) : null}
        </div>
        {filteredPosts && filteredPosts.length > 0 ? (
          <BlogPostPreviewGrid nodes={filteredPosts} />
        ) : (
          <span className="filter__no-results">
            Beklager, men det er ingen artikler under dette filtert.
          </span>
        )}
      </Container>
    </Layout>
  );
};

export default BlogPage;
